@import '../../sass/colors';
@import '../../sass/dimensions';


.non_current_tab {
    @media (max-width: $mobile) {
        display: none !important;
    }
}


.tab {
    user-select: none;
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;

    @media (max-width: $mobile) {
        display: flex;
        pointer-events: all;
        justify-content: space-between;
        //padding: 22px;
        align-items: center;
        box-shadow: 5px 5px 20px rgba(104, 87, 126, 0.1);
    }

    .content {
        font-family: 'TTNormsRegular';
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.01em;
        color: color(tab_title);
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;

        .number_span {
            display: none;

            @media (max-width: $mobile) {
                display: inline-block;
            }
        }



    }

    .selected {
        color: color(tab_title_selected);
        font-family: 'TTNormsBold';

        @media (max-width: $mobile) {
            color: black;
        }
    }



    .underline {
        position: absolute;
        width: 30%;
        height: 3px;
        bottom: 0px;
        left: 35%;
        background: color(tab_title_selected);

        @media (max-width: $mobile) {
            display: none;
        }

    }

    .no_underline {
        display: none;
    }

    .left_arrow {
        display: none;

        @media (max-width: $mobile) {
            display: block;
            pointer-events: all;

            img {
                width: 10px;
                height: 10px;
            }

            padding: 15px 22px;
            z-index: $tab_title_z_index+1;
        }
    }

    .right_arrow {
        display: none;

        @media (max-width: $mobile) {
            display: block;
            pointer-events: all;

            img {
                width: 10px;
                height: 10px;
            }

            padding: 15px 22px;
            z-index: $tab_title_z_index+1;
        }

    }

}