@import '../../../sass/colors';
@import '../../../sass/dimensions';


.custom_select {
    @extend %custom_select;
}

.custom_select_disabled {
    @extend %custom_select;
    cursor: not-allowed !important;
    pointer-events: none !important;
    opacity: 0.5;
}


%custom_select {
    position: relative;


    .title_container {
        width: 100%;
        background-color: rgba(218, 222, 227, 0.5);
        color: color(custom_select_title);
        border: 1px solid color(custom_select_border);
        border-radius: 5px;
        display: flex;
        flex-wrap: nowrap;
        padding: 13px 20px;
        justify-content: space-between;
        align-items: center;
        user-select: none;
        cursor: pointer;

        &__open {
            @extend .title_container;
            border-bottom: none;
            border-radius: 5px 5px 0px 0px;
        }


        &:active,
        &:focus,
        &:visited {
            outline: none;
        }

        p {
            margin: 0px;
            padding: 0px;
            font-family: 'TTNormsRegular';
            font-size: 16px;
            line-height: 19px;
        }

        img {
            margin-left: 14px;
        }


    }


    .custom_select_container {
        position: absolute;
        width: 100%;
        z-index: 20; //Increase if not drawing over others
        overflow: scroll;
        background-color: color(custom_select_bg);
        border-radius: 0px 0px 5px 5px;
        border: 1px solid color(custom_select_border);
        border-top: none;
        padding: 24px 20px;
        max-height: $dropdown_max_height;

        &__closed {
            display: none;
        }
        
    }

    .is_modal {
        background-color: white !important;
        z-index: $save_btn_z_index + 1;
    }





}