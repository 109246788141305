@import '../../sass/colors';
@import '../../sass/dimensions';

.gauge_chart_section {
    margin: 0px;
    padding: 30px;

    @media (max-width: $mobile) {
        padding: 20px;
    }

    @media (max-width: $sm) {
        padding: 20px 4px 20px 4px;
    }

    .chart_content {
        margin: 12px 0px;
        text-align: center;

        div {
            margin: 0px auto;
            width: 270px;
            height: 135px;
            position: relative;

            img {
                width: 270px;
                height: 135px;
            }

            p {
                margin: 0px;
                padding: 0px;
                font-family: 'TTNormsBold';
                font-weight: 500;
                font-size: 65px;
                line-height: 24px;
                letter-spacing: 0.1px;
                position: absolute;
                bottom: 15px;
                //85px for three sentence words and 100px for two letter 
                //words and 120px for one letter words
            }

        }
        
    }

}

.survey_frequency_header {
    padding: 0px;
    margin: 0px;
    font-family: 'TTNormsBold';
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: color(survey_frequency_header);
}


.survey_frequency_description {
    margin: 0px;
    padding: 0px;
    font-family: 'TTNormsRegular';
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: color(survey_frequency_description);

}