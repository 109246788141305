@import '../../sass/colors';
@import '../../sass/dimensions';



.time_set_container {
    margin: 16px 0px 34px 0px;
    display: grid;
    grid-template-columns: 1fr 120px 1fr;
    align-items: center;

    @media (max-width: $mobile) {
        grid-template-columns: 1fr;
        margin: 16px 0px 0px 0px;
        gap: 20px;
    }

    .divider_image {
        margin: 0 auto;
    }

    .select_container {
        margin-top: 20px;
        display: grid;
        gap: 12px;
    }

}