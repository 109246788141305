@import '../../sass/colors';
@import '../../sass/dimensions';

.no_content {
    position: absolute;
    width: 100%;
    bottom: 0px; //This should be the height of the bottom navbar 
    left: 0px;
}

.content {
    position: relative;
}

footer {
    background-color: color(footer_bg);
    display: flex;
    flex-wrap: wrap;
    color: white;
    font-family: 'TTNormsLight';
    font-size: 0.8em;
    padding: 40px 100px;

    @media (max-width: $footer_breakpoint) {
        padding: 40px 16px;
    }

    .sc_container {

        display: flex;
        flex: 0 0 50%;
        max-width: 50%;

        img {
            width: auto;
            height: 90px;
            align-self: center;
        }

        p {
            margin-left: 40px;


        }

        @media (max-width: $footer_breakpoint) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    
    .licence_container {

        display: flex;
        align-items: center;
        max-width: 50%;
        flex: 0 0 50%;

        @media (max-width: $footer_breakpoint) {
            margin-top: 16px;
            flex: 0 0 100%;
            max-width: 100%;
        }

        a {
            color: white;
            text-decoration: none;
        }

    }
    
}