@font-face {
  font-family: "TTNormsBold";
  src: url('./fonts/TTNorms-Bold.otf') format('opentype');
}

@font-face {
  font-family: "TTNormsRegular";
  src: url('./fonts/TTNorms-Regular.otf') format('opentype');
}

@font-face {
  font-family: "TTNormsMedium";
  src: url('./fonts/TTNorms-Medium.otf') format('opentype');
}

@font-face {
  font-family: "TTNormsLight";
  src: url('./fonts/TTNorms-Light.otf') format('opentype');
}


body {
  margin: 0;
  font-family: "TTNormsRegular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@-moz-document url-prefix() {
  * {
    scrollbar-width: none !important;
  }
}

::-webkit-scrollbar {
  display: none;
}

