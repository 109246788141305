@import '../../sass/colors';
@import '../../sass/dimensions';

.modal_header_btn {
    user-select: none;
    cursor: pointer;
    border: none;
    background-color: white;
    display: flex;
    flex-wrap: nowrap;
    border: 1px solid color(modal_header_border);
    border-radius: 30px;
    padding: 20px;
    align-items: center;

    @media (max-width: $mobile) {
        justify-content: center;
        width: 200px;
    }

    p {
        margin: 0px 0px 0px 8px;
        padding: 0px;
        font-family: 'TTNormsBold';
        font-size: 16px;
        line-height: 20px;
        color: color(modal_title);
    }


    &:active,
    &:focus,
    &:visited {
        outline: none;
    }

}


.modal_selected {
    @extend .modal_header_btn;
    border: 2px solid color(modal_header_border_selected);

    p {
        color: color(modal_header_selected);
    }
    
}