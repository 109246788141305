@import '../../sass/dimensions';
@import '../../sass/colors';

.custom_progress_footer_section {
    display: grid;
    grid-template-columns: 1fr auto;
}

.progress_footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    background-color: transparent;
}

.footer_progress {
    align-items: flex-start;
    background-color: transparent;
    color: color(progress_footer);
    font-family: 'TTNormsRegular';
    line-height: 17px;
    font-size: 14px;
    letter-spacing: 0.01em;
}


.bar_label_footer {
    font-family: 'TTNormsBold';
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: color(bar_label);
    margin: auto 0;
    margin-left: 30px;
    visibility: hidden;

    @media (max-width: $mobile) {
        margin-left: 15px;
    }
}

.progress_footer_label {
    margin: 8px 0px 0px 0px;
    padding: 0px;
    font-family: 'TTNormsRegular';
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: color(progress_footer);
    text-align: center;

    @media (max-width: $mobile) {
        margin: 0px;
    }
}