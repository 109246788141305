@import '../../sass/colors';
@import '../../sass/dimensions';

.page_title_section {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 10px;
    align-items: center;

    .download_all_data_btn {
        background-color: transparent;
        color: color(apply_filter_btn_border);
        border: 2px solid color(apply_filter_btn_border);
        //box-shadow: 0px 1px 30px rgba(63, 63, 68, 0.15);
        text-decoration: none;
        border-radius: 40px;
        display: flex;
        padding: 18px 16px;
        justify-content: center;
        user-select: none;
        cursor: pointer;
        
        @media (max-width: $mobile) {
            padding: 15px;
            border-radius: 50%;
        }

        &:active,
        &:focus,
        &:visited {
            outline: none;
        }

        p {
            margin: 0px 0px 0px 16px;
            padding: 0px;
            font-family: 'TTNormsBold';
            font-size: 16px;
            line-height: 19px;

            @media (max-width: $mobile) {
                display: none;
            }
        }
    }
    
}

.page_title {
    margin: 0px;
    padding: 0px;
    font-family: 'TTNormsBold';
    font-size: 40px;
    line-height: 19px;
    color: color(page_title);

    @media (max-width: $mobile) {
        font-size: 30px;
        line-height: 30px;
    }


}
