@import '../../sass/dimensions';
@import '../../sass/colors';

.mixed_chart {
    width: 100%;
    height: 325px !important;

    @media (max-width: $mobile) {
        height: 200px !important;
    }
}