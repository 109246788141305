@import '../../sass/colors';
@import '../../sass/dimensions';

.survey_locations_section {

    display: grid;
    grid-template-columns: 75% 25%;

    @media (max-width: $mobile) {
        grid-template-columns: 1fr;
    }


}

.survey_locations_section__comparison {
    display: grid;
    grid-template-columns: 1fr;

}