@import '../../sass/colors';

//This is for the card border

.card {
    border: none !important;

    .card-body {
        flex: none !important;
    }
}

.comparison_toggle_header {
    padding: 20px;
    margin: 0px 0px 16px 0px;
    display: grid;
    gap: 20px;
    border: 1px solid color(toggle_header_border);
    border-radius: 10px;

    .comparison_icon {
        margin: 0 auto;
    }

    %dataset {
        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .text {
            margin: 0;
            padding: 0;
            margin: 0px 16px;
            font-size: 16px;
            line-height: 19px;
            color: color(toggle_header_txt);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: 'TTNormsBold';
        }

        .reset_text {
            margin: 0px;
            padding: 0px;
            margin: 0px 12px 0px 0px;
            font-size: 14px;
            line-height: 19px;
            text-align: right;
            text-decoration-line: underline;
            color: color(toggle_header_txt);
            cursor: pointer;
            user-select: none;
        }

        .remove_text {
            margin: 0px;
            padding: 0px;
            margin: 0px 12px 0px 0px;
            font-size: 14px;
            line-height: 19px;
            text-align: right;
            text-decoration-line: underline;
            color: color(toggle_header_txt);
            cursor: pointer;
            user-select: none;
        }


    }

    .dataset_one {
        @extend %dataset;
    }

    .dataset_two {
        @extend %dataset;
    }


}

.toggle_header {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    margin: 0px 0px 16px 0px;
    justify-content: space-between;
    border: 1px solid color(toggle_header_border);
    border-radius: 10px;

    &__open {
        @extend .toggle_header;
        border-radius: 10px 10px 0px 0px;
        margin: 0px;
        border-bottom: none;
    }

    .toggle_header_content {
        display: grid;
        width: 100%;
        grid-template-columns: auto 1fr auto;
        align-items: center;
    }

    .text {
        margin: 0;
        padding: 0;
        margin: 0px 16px;
        font-size: 16px;
        line-height: 19px;
        color: color(toggle_header_txt);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        //width: calc(100% - 32px);
    }

    .reset_text {
        margin: 0px;
        padding: 0px;
        margin: 0px 12px 0px 0px;
        font-size: 14px;
        line-height: 19px;
        text-align: right;
        text-decoration-line: underline;
        color: color(toggle_header_txt);
        cursor: pointer;
        user-select: none;
    }

    .reset_text_disabled {
        display: none;
    }


}