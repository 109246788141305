@import '../../sass/dimensions';
@import '../../sass/colors';

.litter_density_per_type_chart_section {

    margin: 0px;
    padding: 0px;

    .chart_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;

        @media (max-width: $mobile) {
            padding: 10px;
        }

        p {
            margin: 0px 8px 0px 0px;
            padding: 0px;
            font-family: 'TTNormsBold';
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: color(item_bar_chart_header);
        }

    }

}