@import '../sass/colors';
@import '../sass/dimensions';

.litter_density_per_type_chart_container {
    margin-top: 30px;

    @media (max-width: $mobile) {
        margin-top: 16px;
    }
}

.total_summary_card_container {
    margin-top: 20px;
}

.total_summary_card_container__comparison {
    margin-top: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;

    @media (max-width: $mobile) {
        grid-template-columns: 1fr; 
    }
    
}

.litter_type_table_container {
    margin-top: 20px;
}

.litter_type_subtitle_container {
    margin-top: 55px;
    display: grid;
    grid-template-columns: 1fr auto;
}

.litter_type_table_container__comparison {
    margin-top: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;

    @media (max-width: $litter_type_table_container_breakpoint) {
        grid-template-columns: 1fr;
    }

    @media (max-width: $mobile) {
        overflow-x: scroll;
        grid-template-columns: 1fr;
    }
}

.litter_density_per_type_chart_container__comparison {
    margin-top: 20px;
    max-width: 100%;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;

    @media (max-width: $mobile) {
        grid-template-columns: 1fr;
    }
}

.take_action_btn {

    margin: 0px;
    padding: 16px 50px;
    border-radius: 40px;
    border: 2px solid color(take_action_btn);
    font-size: 16px;
    line-height: 19px;
    font-family: 'TTNormsBold';
    color: color(take_action_btn);
    background-color: transparent;
    user-select: none;

    &:active,
    &:focus,
    &:visited {
        outline: none;
    }

    @media (max-width: $mobile) {
        padding: 16px;
    }


}