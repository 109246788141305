@import '../../../sass/colors';


.checkbox_container {

    input[type='checkbox'] {
        opacity: 0;
        position: absolute;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
    }

    label {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        font-family: 'TTNormsRegular';
        font-size: 16px;
        line-height: 19px;
        cursor: pointer;
        padding-left: 34px;
    }

    input[type='checkbox']+label:before {
        content: '';
        background: #fff;
        border: 1px solid #575757;
        box-sizing: border-box;
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        padding: 2px;
        margin-right: 16px;
        text-align: center;
        border-radius: 4px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
    }

    input[type='checkbox']:checked+label:before {
        background-color: color(primary);
    }


}