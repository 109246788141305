@import '../../sass/colors';
@import '../../sass/dimensions';

.comparison_card_container {
    background-color: white;
    box-shadow: 5px 5px 20px rgba(104, 87, 126, 0.1);
    border-radius: 4px;
    display: flex;
    flex-wrap: nowrap;
    height: fit-content;

    .comparison_card_content {
        width: calc(100% - #{$comparison_flier_width});
    }

    .loading {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .failed_no_data {
        font-family: 'TTNormsRegular';
        font-size: 16px;
        margin: 12px 0px;
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

%comparison_card_flier {
    box-shadow: 5px 5px 20px rgba(104, 87, 126, 0.1);
    height: auto;
    width: $comparison_flier_width;
}

.comparison_card_flier_default {
    @extend %comparison_card_flier;
    background-color: color(data_set_default);
}

.comparison_card_flier_set_one {
    @extend %comparison_card_flier;
    background-color: color(data_set_one);
}

.comparison_card_flier_set_two {
    @extend %comparison_card_flier;
    background-color: color(data_set_two);
}