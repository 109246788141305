@import '../../sass/colors';
@import '../../sass/dimensions';


.survey_effort_stats_section {
    display: grid;
    grid-template-columns: 1fr 1fr;


    @media (max-width: $mobile) {
        grid-template-columns: 1fr;
    }

}

.survey_effort_stats_section__comparison {
    display: grid;
    grid-template-columns: 1fr;
}








