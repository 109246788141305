@import '../../sass/colors';
@import '../../sass/dimensions';


.horizontal_divider {
    width: 100%;
    height: 0px;
    border: 1px solid color(horizontal_divider);
}

.apply_filter_container {
    display: flex;
    flex-wrap: wrap !important;
    justify-content: flex-end;
    margin-top: 1em; //This unusually changes between all of the filter sections
}

.apply_filter_btn {
    margin: 18px 0px 24px 0px;
    width: 100%;
    background-color: color(apply_filter_btn);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    user-select: none;
    font-family: 'TTNormsBold';
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.01em;
    cursor: pointer;

    &:active,
    &:focus,
    &:visited {
        outline: none;
    }

}