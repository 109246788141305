@import '../../sass/colors';
@import '../../sass/dimensions';


.info_group_section {
    display: grid;
}

.info_group_section_unstacked {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: $sm) {
        grid-template-columns: 1fr;
    }


}

.info_item_container {
    padding: 30px;
    border-bottom: 1px solid color(horizontal_divider);

    &:last-child {
        border-bottom: none;
    }

    @media (max-width: $mobile) {
        padding: 16px;
    }

}



.info_item_container_unstacked {
    padding: 30px;

    &:nth-child(1),
    &:nth-child(2) {
        border-bottom: 1px solid color(horizontal_divider);
    }

    &:nth-child(even) {
        border-left: 1px solid color(horizontal_divider);
    }

    @media (max-width: $mobile) {
        padding: 16px;
    }


    @media (max-width: $sm) {
        padding: 16px;
        border-bottom: 1px solid color(horizontal_divider);

        &:nth-child(even) {
            border-left: none;
        }

        &:last-child {
            border-bottom: none;
        }
    }


}


.info_group_section_survey_effort {
    border-left: 1px solid color(horizontal_divider);
    background-color: color(info_group_survey_effort);

    @media (max-width: $mobile) {
        border-left: none;
        border-top: 1px solid color(horizontal_divider);
    }
}

.info_group_section_survey_effort__comparison {
    background-color: color(info_group_survey_effort);
    border-left: none;
    border-top: 1px solid color(horizontal_divider);
}