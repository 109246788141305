@import '../../sass/colors';
@import '../../sass/dimensions';

.litter_density_info_item {

    .title_section {

        @media (max-width: $mobile) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        @media (max-width: $sm) {
            display: block;
        }
        
        .title {

            margin: 0px;
            padding: 0px;
            font-size: 12px;
            font-family: 'TTNormsBold';
            line-height: 14px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: color(summary_section_title);
    
            @media (max-width: $mobile) {
                font-size: 10px;
                line-height: 12px;
            }
    
        }

        .title_description {

            margin: 0px;
            padding: 0px;
            font-size: 12px;
            font-family: 'TTNormsMedium';
            line-height: 12px;
            letter-spacing: 0.05em;
            color: color(summary_section_title);

            @media (max-width: $mobile) {
                font-size: 10px;
                line-height: 12px;
                margin-left: 16px;
            }

            @media (max-width: $sm) {
                margin-left: 0px;
            }
    
        }

    }

    .content_section {
        margin-top: 12px;


        .median_litter_density {
            margin: 0px;
            padding: 0px;
            font-size: 30px;
            font-family: 'TTNormsRegular';
            line-height: 24px;
            letter-spacing: 0.1em;
            color: color(summary_section_value);
            align-self: center;

            @media (max-width: $mobile) {
                font-size: 20px;
                line-height: 25px;
            }
        }

        .extremes_container {
            display: flex;
            flex-wrap: wrap;
            margin-top: 10px;
            .value {
                margin: 0px;
                padding: 0px;
                font-size: 14px;
                font-family: 'TTNormsMedium';
                line-height: 14px;
                letter-spacing: 0.05em;
                color: color(summary_section_value);
                margin-left: 12px;
            }
            
            .description {
                margin: 0px;
                padding: 0px;
                font-size: 14px;
                font-family: 'TTNormsBold';
                line-height: 14px;
                letter-spacing: 0.05em;
                color: color(summary_section_title);
                
                
            }
    
        }
    }

    
    
    



}