
$colors: (
    primary: #5E309B,
    back_button_bg: #DCDCE4,
    all_selected_filter_header: #777777,
    selected_filter_header: black,
    horizontal_divider: #D9D5EC,
    custom_select_bg: #ECEEF1,
    custom_select_border: #D9D5EC,
    custom_select_title: #2E3B49,
    date_picker_title_bar_bg: #7946E6,
    date_picker_selected: #7946E6,
    main_bg: #F2F5F8,
    toggle_header_border: #D9D5EC,
    apply_filter_btn:#7946E5,
    apply_filter_btn_border: #7946E6,
    toggle_header_txt: #2E3B49,
    filter_btn: #7946E6,
    done_btn: #7946E6,
    footer_bg: #232424,
    tab_title: #2E3B49,
    tab_title_selected: #7946E6,
    modal_title: #2E3B49,
    modal_subtitle: #2E3B49,
    save_btn: #7946E6,
    save_btn_disabled: #C1C1C1,
    modal_header_border: #D9D5EC,
    modal_header_border_selected: #7946E6,
    modal_header_selected: #7946E6,
    modal_set_title: #6E6893,
    page_title: #2E3B49,
    page_sub_title: #2E3B49,
    data_set_view_content: #2E3B49,
    data_set_view_title: #6E6893,
    data_set_default: #5E2B9D,
    data_set_one: #5E2B9D,
    data_set_two: #F05282,
    vertical_divider: #D9D5EC,
    summary_section_title: #6E6893,
    summary_section_value: #2E3B49,
    summary_section_description: #6E6893,
    progress_bar_unselected: #F3F8FC,
    progress_bar_text: #2E3B49,
    bar_label: #2E3B49,
    progress_footer: #4C5862,
    toggle_text: #6E6893,
    toggle_bg: #ECEEF1,
    item_bar_chart_header: #6E6893,
    custom_tooltip_bg: #232323,
    view_all_btn: #FF5692,
    litter_density_legend_name: #4C5862,
    litter_density_chart_footer_bg: #F7F9FA,
    litter_density_chart_footer_set_title: #6E6893,
    treemap_legend_name: #2E3B49,
    custom_table_header_bg: #F7F9FA,
    custom_table_header: #6E6893,
    custom_table_row: #25213B,
    custom_table_number_cell: #6E6893,
    custom_table_footer_bg: #F7F9FA,
    take_action_btn:#7946E6,
    view_all_surveys_btn: #7946E6,
    survey_frequency_header: #6E6893,
    survey_frequency_description: #141D2E,
    info_group_survey_effort: #F3F8FC,
    treemap_legend_bg: #F3F8FC,
    header_link_hover: #FF5692
);




@function color($color-name) {
    @return map-get($colors, $color-name)
}
  