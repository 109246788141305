@import '../../sass/colors';
@import '../../sass/dimensions';

.modal {
    position: fixed;
    padding-top: 134px;
    display: block;
    left: 0px;
    top: 0px;
    overflow: scroll;
    background-color: rgba(20, 29, 46, 0.6);
    width: 100%;
    height: 100%;
    z-index: $mobile_sidebar_z_index + 1;

    @media (max-width: $mobile) {
        padding: 0px;
    }
}


.modal_content_toggle_selected {
    @media (max-width: $mobile) {
        height: auto !important;
    }
}

.modal_content {
    background-color: white;
    margin: auto;
    width: 75%;
    border-radius: 4px;
    box-shadow: 0px 1px 30px rgba(63, 63, 68, 0.15);
    padding: 30px 50px;

    @media (max-width: $mobile) {
        width: 100vw;
        height: 100vh; //This is the issue for comparison modal in firefox
        border-radius: 0px;
        padding: 30px 20px 65px 20px;
        overflow: scroll;
    }

    .collapsing {
        transition: none !important; // shows content twice when content has animation
    }

    .modal_title_container {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;

        @media (max-width: $mobile) {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr;
            justify-content: center;
        }

        h1 {
            margin: 0px;
            padding: 0px;
            font-size: 30px;
            font-family: 'TTNormsBold';
            line-height: 19px;
            color: color(modal_title);

            @media (max-width: $mobile) {
                grid-row: 2;
                justify-self: center;
                line-height: 40px;
            }

        }


        img {
            margin: 0px;
            padding: 0px;
            width: 15px;
            height: 15px;
            cursor: pointer;

            @media (max-width: $mobile) {
                grid-row: 1;
                justify-self: right;
                margin-bottom: 30px;
            }
        }


    }

    .subtitle_container {

        margin: 40px 0px 0px 0px;

        @media (max-width: $mobile) {
            display: grid;
            grid-template-columns: 1fr;
            justify-items: center;
        }

        h2 {
            margin: 0px;
            padding: 0px;
            font-size: 20px;
            font-family: 'TTNormsBold';
            line-height: 19px;
            color: color(modal_subtitle);
        }

        p {
            margin: 10px 0px 0px 0px;
            padding: 0px;
            font-size: 16px;
            font-family: 'TTNormsRegular';
            line-height: 19px;
            letter-spacing: 0.01em;
            color: rgba(46, 59, 73, 0.5);
        }


    }

    .footer_button_grp {

        display: flex;
        justify-content: flex-end;
        flex-wrap: nowrap;
        margin-top: 24px;
        font-family: 'TTNormsBold';
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.01em;


        .cancel_btn {
            border: none;
            color: color(modal_subtitle);
            background-color: transparent;
            border-radius: 30px;
            padding: 16px 0px;
            user-select: none;
            text-align: center;
            width: 150px;
            cursor: pointer;


            &:active,
            &:focus,
            &:visited {
                outline: none;
            }

            @media (max-width: $mobile) {
                display: none;
            }


        }

        .save_btn {
            border: none;
            color: white;
            background-color: color(save_btn);
            border-radius: 30px;
            padding: 16px 0px;
            user-select: none;
            text-align: center;
            width: 150px;
            cursor: pointer;
            z-index: $save_btn_z_index;

            &:active,
            &:focus,
            &:visited {
                outline: none;
            }

            @media (max-width: $mobile) {
                position: fixed;
                bottom: 0px;
                left: 0px;
                width: 100vw;
                border-radius: 0px;
                box-shadow: -5px -5px 10px rgba(46, 59, 73, 0.1);
            }


        }

        .save_btn__disabled {
            @extend .save_btn;
            pointer-events: none;
            background-color: color(save_btn_disabled);
        }




    }


    .header_btn_grp {
        display: grid;
        grid-template-columns: auto auto auto auto 1fr;
        gap: 16px;
        margin: 20px 0px 24px 0px;

        @media (max-width: $mobile) {
            grid-template-columns: 1fr;
            justify-items: center;
        }
    }

    .horizontal_divider {

        @media (max-width: $mobile) {
            display: none;
        }
    }



}