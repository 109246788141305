@import '../../sass/colors';
@import '../../sass/dimensions';

.page_sub_title {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .title_text {
        margin: 0px;
        padding: 0px;
        font-family: 'TTNormsBold';
        font-size: 20px;
        line-height: 19px;
        color: color(page_sub_title);
    }
    
    .helper_icon {
        margin-left: 10px;
    }

}

.page_sub_title_tooltip {
    background: color(custom_tooltip_bg);
    box-shadow: 0px 1px 30px rgba(63, 63, 68, 0.15);
    border-radius: 4px;
    margin: 0px 0px 10px 65px;
    padding: 10px;
    font-family: 'TTNormsRegular';
    font-size: 12px;
    color: white;
    max-width: $tooltip_max_width;
}
