@import '../../../sass/colors';

.date_picker_day {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 2em;
	/* Included Margin and Padding seperately to ensure that 
		the background-color is applied to the selected date 
		appropriately 
	*/
	margin: 0.5em 0em 0.5em 0em;
	padding: 0em 0.5em 0em 0.5em;
	@media (--viewport-mobile) {
		margin: 0.1em 0em 0.1em 0em;
		padding: 0em;
	}
}

.date_picker_other_month {
	opacity: 0;
	pointer-events: none;
}

.date_picker_above_max_date {
	opacity: 0.5;
	pointer-events: none;
}

.date_picker_link {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.44px;
	//width: 2em;
	//height: 2em;
	border-radius: 2em;
	padding: 0;
	&:link, &:visited, &:hover {
		color: inherit;
		text-decoration: none;
	}

}

.date_highlight_middle { //This is the class that is called when a date is selected
	border-radius: 4px;
	background-color: color(date_picker_selected);
	margin: 0px;
	color:white;
	padding: 0px;
}

.date_hover {
	background: grey;
}

.date_picker_header {
	display: flex;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.4px;
	text-align: center;
	align-items: center;
	justify-content: center;
	margin: 1em 0em;
	font-family: 'TTNormsRegular';
}

.date_picker_title_bar {
	background-color: color(date_picker_title_bar_bg);
	margin: 0px !important;
	padding: 16px;
	font-size: 14px;
	color: white;
	text-transform: uppercase;
	font-family: 'TTNormsBold';
	line-height: 17px;
	letter-spacing: 0.75px;
}

.arrow {
	flex: 0 0 14px;
}

.arrow_left {
	width: 14px;
	height: 8px;
    color: black;
}

.arrow_right {
	width: 14px;
	height: 8px;
    color: black;
}

.date_month {
	flex: 1 1 auto;
	font-weight: bold;
	font-size: 17px;
}


.date_picker_grid {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	//min-width: 21em;
	//max-width: 25em;
	font-size: 90%;
}


.date-picker-grid > *:nth-child(1) { -ms-grid-column: 1; -ms-grid-row: 1 }
.date-picker-grid > *:nth-child(2) { -ms-grid-column: 2; -ms-grid-row: 1 }
.date-picker-grid > *:nth-child(3) { -ms-grid-column: 3; -ms-grid-row: 1 }
.date-picker-grid > *:nth-child(4) { -ms-grid-column: 4; -ms-grid-row: 1 }
.date-picker-grid > *:nth-child(5) { -ms-grid-column: 5; -ms-grid-row: 1 }
.date-picker-grid > *:nth-child(6) { -ms-grid-column: 6; -ms-grid-row: 1 }
.date-picker-grid > *:nth-child(7) { -ms-grid-column: 7; -ms-grid-row: 1 }
.date-picker-grid > *:nth-child(8) { -ms-grid-column: 1; -ms-grid-row: 2 }
.date-picker-grid > *:nth-child(9) { -ms-grid-column: 2; -ms-grid-row: 2 }
.date-picker-grid > *:nth-child(10) { -ms-grid-column: 3; -ms-grid-row: 2 }
.date-picker-grid > *:nth-child(11) { -ms-grid-column: 4; -ms-grid-row: 2 }
.date-picker-grid > *:nth-child(12) { -ms-grid-column: 5; -ms-grid-row: 2 }
.date-picker-grid > *:nth-child(13) { -ms-grid-column: 6; -ms-grid-row: 2 }
.date-picker-grid > *:nth-child(14) { -ms-grid-column: 7; -ms-grid-row: 2 }
.date-picker-grid > *:nth-child(15) { -ms-grid-column: 1; -ms-grid-row: 3 }
.date-picker-grid > *:nth-child(16) { -ms-grid-column: 2; -ms-grid-row: 3 }
.date-picker-grid > *:nth-child(17) { -ms-grid-column: 3; -ms-grid-row: 3 }
.date-picker-grid > *:nth-child(18) { -ms-grid-column: 4; -ms-grid-row: 3 }
.date-picker-grid > *:nth-child(19) { -ms-grid-column: 5; -ms-grid-row: 3 }
.date-picker-grid > *:nth-child(20) { -ms-grid-column: 6; -ms-grid-row: 3 }
.date-picker-grid > *:nth-child(21) { -ms-grid-column: 7; -ms-grid-row: 3 }
.date-picker-grid > *:nth-child(22) { -ms-grid-column: 1; -ms-grid-row: 4 }
.date-picker-grid > *:nth-child(23) { -ms-grid-column: 2; -ms-grid-row: 4 }
.date-picker-grid > *:nth-child(24) { -ms-grid-column: 3; -ms-grid-row: 4 }
.date-picker-grid > *:nth-child(25) { -ms-grid-column: 4; -ms-grid-row: 4 }
.date-picker-grid > *:nth-child(26) { -ms-grid-column: 5; -ms-grid-row: 4 }
.date-picker-grid > *:nth-child(27) { -ms-grid-column: 6; -ms-grid-row: 4 }
.date-picker-grid > *:nth-child(28) { -ms-grid-column: 7; -ms-grid-row: 4 }
.date-picker-grid > *:nth-child(29) { -ms-grid-column: 1; -ms-grid-row: 5 }
.date-picker-grid > *:nth-child(30) { -ms-grid-column: 2; -ms-grid-row: 5 }
.date-picker-grid > *:nth-child(31) { -ms-grid-column: 3; -ms-grid-row: 5 }
.date-picker-grid > *:nth-child(32) { -ms-grid-column: 4; -ms-grid-row: 5 }
.date-picker-grid > *:nth-child(33) { -ms-grid-column: 5; -ms-grid-row: 5 }
.date-picker-grid > *:nth-child(34) { -ms-grid-column: 6; -ms-grid-row: 5 }
.date-picker-grid > *:nth-child(35) { -ms-grid-column: 7; -ms-grid-row: 5 }
.date-picker-grid > *:nth-child(36) { -ms-grid-column: 1; -ms-grid-row: 6 }
.date-picker-grid > *:nth-child(37) { -ms-grid-column: 2; -ms-grid-row: 6 }
.date-picker-grid > *:nth-child(38) { -ms-grid-column: 3; -ms-grid-row: 6 }
.date-picker-grid > *:nth-child(39) { -ms-grid-column: 4; -ms-grid-row: 6 }
.date-picker-grid > *:nth-child(40) { -ms-grid-column: 5; -ms-grid-row: 6 }
.date-picker-grid > *:nth-child(41) { -ms-grid-column: 6; -ms-grid-row: 6 }
.date-picker-grid > *:nth-child(42) { -ms-grid-column: 7; -ms-grid-row: 6 }
.date-picker-grid > *:nth-child(43) { -ms-grid-column: 1; -ms-grid-row: 7 }
.date-picker-grid > *:nth-child(44) { -ms-grid-column: 2; -ms-grid-row: 7 }
.date-picker-grid > *:nth-child(45) { -ms-grid-column: 3; -ms-grid-row: 7 }
.date-picker-grid > *:nth-child(46) { -ms-grid-column: 4; -ms-grid-row: 7 }
.date-picker-grid > *:nth-child(47) { -ms-grid-column: 5; -ms-grid-row: 7 }
.date-picker-grid > *:nth-child(48) { -ms-grid-column: 6; -ms-grid-row: 7 }
.date-picker-grid > *:nth-child(49) { -ms-grid-column: 7; -ms-grid-row: 7 }
.date-picker-grid > *:nth-child(50) { -ms-grid-column: 1; -ms-grid-row: 8 }
.date-picker-grid > *:nth-child(51) { -ms-grid-column: 2; -ms-grid-row: 8 }
.date-picker-grid > *:nth-child(52) { -ms-grid-column: 3; -ms-grid-row: 8 }
.date-picker-grid > *:nth-child(53) { -ms-grid-column: 4; -ms-grid-row: 8 }
.date-picker-grid > *:nth-child(54) { -ms-grid-column: 5; -ms-grid-row: 8 }
.date-picker-grid > *:nth-child(55) { -ms-grid-column: 6; -ms-grid-row: 8 }
.date-picker-grid > *:nth-child(56) { -ms-grid-column: 7; -ms-grid-row: 8 }
