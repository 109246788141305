@import "../../sass/colors";
@import "../../sass/dimensions";

main {
  position: relative;
  float: right;
  width: calc(100vw - #{$sidebar_width});
  height: calc(100vh - #{$header_height});
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: color(main_bg);

  @media (max-width: $mobile) {
    width: 100vw;
    height: 100vh;
    float: none;
  }

  .survey_type_main_view {
    display: none;
    padding: 0 30px 20px 30px;

    @media (max-width: $mobile) {
      display: block;
    }
  }

  .filter_btn {
    cursor: pointer;
    width: 100%;
    border: none;
    background-color: color(filter_btn);
    box-shadow: -5px -5px 10px rgba(46, 59, 73, 0.1);
    color: white;
    font-size: 18px;
    line-height: 19px;
    font-family: "TTNormsBold";
    text-align: center;
    padding: 16px;
    justify-content: center;
    display: none;
    flex-wrap: nowrap;

    p {
      margin: 0px;
      padding: 0px;
      margin-right: 10px;
    }

    &:active,
    &:focus,
    &:visited {
      outline: none;
    }

    @media (max-width: $mobile) {
      display: flex;
    }
  }

  .tab_container {
    padding: 40px;
    overflow: scroll;

    @media (max-width: $tab_breakpoint_1) {
      padding: 32px 20px;
    }
  }

  .tab_title_container {
    position: relative;
    width: 100%;
    height: 2.75em;
    background-color: transparent;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    align-items: center;

    @media (max-width: $tab_breakpoint_1) {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }

    @media (max-width: $tab_breakpoint_2) {
      grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
    }

    @media (max-width: $tab_breakpoint_3) {
      grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
    }

    @media (max-width: $mobile) {
      position: fixed;
      bottom: 0px;
      left: 0px;
      background-color: white;
      box-shadow: 5px 5px 20px rgba(104, 87, 126, 0.1);
      z-index: $tab_title_z_index;
      height: auto;
      pointer-events: all;
    }

    &__open {
      @media (max-width: $mobile) {
        display: block;
      }
    }

    &__close {
      @media (max-width: $mobile) {
        display: none;
      }
    }
  }
}
