@import '../sass/colors';
@import '../sass/dimensions';

.litter_density_chart_container {
    margin-top: 20px;
}

.litter_density_item_summary_container {
    margin-top: 20px;
}

.litter_density_item_summary_container__comparison {
    margin-top: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;

    @media (max-width: $mobile) {
        grid-template-columns: 1fr;
    }
}

.litter_density_weight_summary_container {
    margin-top: 20px;
}

.litter_density_weight_summary_container__comparison {
    margin-top: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;

    @media (max-width: $mobile) {
        grid-template-columns: 1fr;
    }
}