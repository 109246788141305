@import '../../sass/dimensions';
@import '../../sass/colors';


.items_bar_chart_section {
    margin: 0px;
    padding: 0px;

    .item_chart_container {
        padding: 20px 20px 20px 20px;

        @media (max-width: $mobile) {
            padding: 10px 5px 10px 10px;
        }
    }



}

.items_bar_chart_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    @media (max-width: $mobile) {
        padding: 10px;
    }

    p {
        margin: 0px 8px 0px 0px;
        padding: 0px;
        font-family: 'TTNormsBold';
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: color(item_bar_chart_header);
    }
}