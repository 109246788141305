@import '../../sass/dimensions';
@import '../../sass/colors';

.custom_progress_section {
    display: grid;
    grid-template-columns: 1fr auto;
}

.progress {
    background-color: color(progress_bar_unselected);
    height: 30px;
    border-radius: 0px;
    position: relative;
}

.progress_label {
    margin: 0px;
    padding: 0px;
    position: absolute;
    right: 16px;
    top: 7px; //We know this cause of the progress height
    font-family: 'TTNormsBold';
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: color(progress_bar_text);
    text-align: center;
}

.bar_label {
    font-family: 'TTNormsBold';
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: color(bar_label);
    margin: auto 0;
    width: 60px;
    padding-left: 8px;
    
}