@import '../../sass/colors';

.content_container {
    display: grid;
    grid-gap: 15px;
}

.litter_type_container {
    border: 1px solid color(toggle_header_border);
    border-top: none;
    border-radius: 0px 0px 10px 10px;
    margin-bottom: 16px;
    padding: 0px 22px;
}