@import '../../sass/dimensions';
@import '../../sass/colors';


.litter_density_chart_footer {

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 20px 20px 20px 8px;
    background-color: color(litter_density_chart_footer_bg);

    @media (max-width: $mobile) {
        flex-wrap: wrap;
    }


    .legend_container {

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .set_title {
            margin: 0px 0px 0px 20px;
            padding: 0px;
            font-family: 'TTNormsBold';
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: color(litter_density_chart_footer_set_title);
            
        }



        .legend {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            margin-left: 20px;
            align-items: center;


            .scatter {
                border-radius: 50%;
                width: 12px;
                height: 12px;
                margin-left: 8px;
            }

            .line {
                height: 4px;
                width: 30px;
                margin-left: 8px;
            }


        }

        .legend_name {
            margin: 0px;
            padding: 0px;
            font-family: 'TTNormsRegular';
            font-size: 12px;
            line-height: 14px;
            color: color(litter_density_legend_name);
        }




    }

    .note_container {

        margin: 0px 20px 0px 8px;
        padding: 0px;
        font-family: 'TTNormsRegular';
        font-size: 12px;
        line-height: 14px;
        color: color(litter_density_legend_name);

        @media (max-width: $litter_density_chart_footer_note_breakpoint) {
            margin: 8px 20px 0px 20px;
        }


    }








}