$header_breakpoint_1: 1450px;

$sidebar_width: 350px;
$header_height: 85px;
$header_z_index: 10;
$dropdown_max_height: 200px;

$mobile_sidebar_z_index: 20;

$mobile: 1024px; //Also inclusive of tablets
$sm: 375px;

$footer_breakpoint: 1380px;

$tab_breakpoint_1: 1380px;
$tab_breakpoint_2: 1200px;
$tab_breakpoint_3: 1065px;

$tab_title_z_index: 20;

$done_btn_z_index: 30;
$save_btn_z_index: 31;

$divider_image_breakpoint: 1200px;

$litter_density_chart_footer_note_breakpoint: 885px;

$treemap_height: 500px;

$comparison_flier_width: 6px;

$tooltip_max_width: 250px;

$litter_type_table_container_breakpoint: 1300px;
$litter_density_table_container_breakpoint: 1300px;
$monitoring_group_table_container_breakpoint: 1500px;


$treemap_legend_breakpoint: 1280px;