@import '../../sass/colors';
@import '../../sass/dimensions';

.custom_toggle_section {
    width: fit-content;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    border-radius: 4px;
    background-color: color(toggle_bg);
    user-select: none;
    cursor: pointer;
}

%custom_toggle_content {
    font-family: 'TTNormsBold';
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: color(toggle_text);
    margin: 0px;
    padding: 12px 20px;

}

.unselected_toggle {
    @extend %custom_toggle_content;
}

.selected_toggle {
    @extend %custom_toggle_content;
    background: white;
    box-shadow: 0px 1px 10px rgba(63, 63, 68, 0.15);
    border-radius: 4px;
}