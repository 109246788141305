@import '../../sass/dimensions';
@import '../../sass/colors';


.side-tabs-container {
    margin-top: 28px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    position: relative;
    .each-tab {
        position: relative;
    }
    a {
        font-family: 'TTNormsRegular';
        text-decoration: underline;
        cursor: pointer;
        font-size: 16px;
    }
    .selected-tab {
        color: #7946E6;
        font-weight: bold;
    }
    .selected-tab:hover {
        color: #7946E6;
        font-weight: bold;
    }
    .unselected-tab {
        font-weight: normal;
    }

    .underline {
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: -10px;
        background: color(tab_title_selected);
    }

    .no_underline {
        display: none;
    }

    .underline-grey {
        position: absolute;
        width: 130%;
        height: 1px;
        top: 33px;
        left: -30px;
        background: #D9D5EC;
    }
}