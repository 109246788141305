@import '../../../sass/colors';
@import '../../../sass/dimensions';

.calendar_dropdown_is_disabled {
    pointer-events: none;
    opacity: 0.5;
}

.calendar_dropdown {
    position: relative;


    .title_container {
        width: 100%;
        background-color: color(custom_select_bg);
        color: color(custom_select_title);
        border: 1px solid color(custom_select_border);
        border-radius: 5px;
        display: flex;
        flex-wrap: nowrap;
        padding: 13px 20px;
        justify-content: space-between;
        align-items: center;
        user-select: none;
        cursor: pointer;

        &__open {
            @extend .title_container;
            border-bottom: none;
            border-radius: 5px 5px 0px 0px;
        }


        &:active,
        &:focus,
        &:visited {
            outline: none;
        }

        p {
            margin: 0px;
            padding: 0px;
            font-family: 'TTNormsRegular';
            font-size: 16px;
            line-height: 19px;
        }


    }


    .calendar_dropdown_container {
        position: absolute;
        width: 100%;
        z-index: $done_btn_z_index + 1; //Increase if not drawing over others
        overflow: scroll;
        background-color: color(custom_select_bg);
        border-radius: 0px 0px 5px 5px;
        border: 1px solid color(custom_select_border);
        border-top: none;
    }

    .is_modal {
        background-color: white !important;
        z-index: $save_btn_z_index + 1;
    }





}