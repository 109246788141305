@import '../../sass/colors';
@import '../../sass/dimensions';

.custom_table_component_container {
    max-width: 100% !important;
    overflow-x: scroll;
}

.custom_table {
    width: 100%;
    .header {
        background-color: color(custom_table_header_bg);

        th {
            font-family: 'TTNormsBold';
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: color(custom_table_header);
            padding: 16px 10px;
            user-select: none;
            cursor: pointer;

            &:first-child {
                padding: 16px 10px 16px 24px;

                @media (max-width: $mobile) {
                    font-size: 10px;
                    line-height: 12px;
                    padding: 16px 4px;
                }

            }

            @media (max-width: $mobile) {
                font-size: 10px;
                line-height: 12px;
                padding: 16px 4px;
            }

        }



        .show_sort_icon {
            @extend %sort_icon;
            display: inline-block;
        }

        .hide_sort_icon {
            @extend %sort_icon;
            display: none;
        }

        %sort_icon {
            margin-left: 8px;

            @media (max-width: $mobile) {
                margin-left: 4px;
            }
        }

    }

    .row {
        border-collapse: collapse;
        font-size: 16px;
        font-family: 'TTNormsRegular';
        line-height: 19px;
        color: color(custom_table_row);
        box-sizing: border-box;
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        border-spacing: 2px;
        border-bottom: 1px solid color(horizontal_divider);

        @media (max-width: $mobile) {
            font-size: 12px;
            line-height: 14px;
        }

        td {
            padding: 16px 10px;

            &:first-child {
                padding: 16px 10px 16px 24px;

                @media (max-width: $mobile) {
                    padding: 16px 4px;
                }

            }


            @media (max-width: $mobile) {
                padding: 16px 4px;
            }
        }

        .number_cell {
            font-family: 'TTNormsBold';
            color: color(custom_table_number_cell);
        }

        &:last-child {
            border-bottom: none;
        }

    }
}

.custom_table_footer {
    background-color: color(custom_table_footer_bg);
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;

    .page_description {

        font-family: 'TTNormsBold';
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.05em;
        color: color(custom_table_number_cell);

        padding: 16px;
    }

    .page_nav_container {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;

        .left_arrow {
            margin: 0px 24px 0px 8px;
            cursor: pointer;
        }

        .right_arrow {
            margin: 0px 24px 0px 0px;
            cursor: pointer;
        }


    }
}