@import '../../sass/colors';
@import '../../sass/dimensions';

.treemap_container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    @media (max-width: $mobile) {
        display: block;
    }

    .chart {
        width: 70%;
        height: $treemap_height;
        padding-top: 14px;
        margin-left: 8px;
        margin-bottom: 8px;

        @media (max-width: $treemap_legend_breakpoint) {
            width: 60%;
        }

        &:focus, &:active, &:focus-within, &:link, &:visited {
            pointer-events: none !important;
        }

        @media (max-width: $mobile) {
            width: 100%;
            padding-top: 10px;
            margin-bottom: 8px;
            margin-left: 4px;
        }
    }
}


.treemap_container__comparison {
    display: block;

    .chart {
        width: 100%;
        height: $treemap_height;
        padding-top: 14px;
        margin-bottom: 8px;

        @media (max-width: $mobile) {
            padding-top: 10px;
            margin-bottom: 8px;
        }
    }

}