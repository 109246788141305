@import '../../sass/colors';
@import '../../sass/dimensions';


.open_nav {
    @media (max-width: $mobile) {
        display: block !important;
    }
}

.close_nav {
    @media (max-width: $mobile) {
        display: none !important;
    }
}

nav {
    float: left;
    width: $sidebar_width;
    position: relative;
    height: calc(100vh - #{$header_height});
    overflow-y: scroll;
    overflow-x: hidden;
    background: white;
    box-shadow: 0px 1px 30px rgba(63, 63, 68, 0.15);
    -moz-box-shadow: 0px 1px 30px rgba(63, 63, 68, 0.15);
    -webkit-box-shadow: 0px 1px 30px rgba(63, 63, 68, 0.15);
    z-index: 2; //This is so that box-shadow is applied
    padding: 40px 30px;

    

    @media (max-width: $mobile) {
        float: none;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0px;
        left: 0px;
        overflow: scroll;
        display: none !important;
        z-index: $mobile_sidebar_z_index;
        padding: 34px 16px 64px 16px; //64px for done btn at the bottom
    }

    .collapsing {
        transition: none !important; // shows content twice when content has animation
    }

    .filters_header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
            margin: 0px;
            padding: 0px;
            font-size: 18px;
            line-height: 19px;
            font-family: "TTNormsBold";
        }

        p {
            margin: 0px;
            padding: 0px;
            text-decoration: underline;
            letter-spacing: 0.01em;
            font-size: 14px;
            line-height: 17px;
            cursor: pointer;
            user-select: none;
        }

    }

    .dropdown_container {
        margin-top: 30px;
        user-select: none;
        width: 100%;
    }

    .add_data_btn {
        width: 100%;
        background-color: transparent;
        color: color(apply_filter_btn_border);
        border: 1px solid color(apply_filter_btn_border);
        //box-shadow: 0px 1px 30px rgba(63, 63, 68, 0.15);
        border-radius: 10px;
        display: flex;
        padding: 16px 0px;
        justify-content: center;
        user-select: none;
        cursor: pointer;


        &:active,
        &:focus,
        &:visited {
            outline: none;
        }

        @-moz-document url-prefix() {
            @media (max-width: $mobile) {
                margin-bottom: 64px;
            }
        }


        p {
            margin: 0px;
            padding: 0px;
            font-family: 'TTNormsBold';
            font-size: 16px;
            line-height: 19px;
            margin-left: 1em;
        }

    }




    .done_btn {
        width: 100%;
        background-color: transparent;
        color: white;
        background-color: color(done_btn);
        box-shadow: -5px -5px 10px rgba(46, 59, 73, 0.1);
        font-family: 'TTNormsBold';
        border: none;
        padding: 16px 0px;
        user-select: none;
        font-size: 18px;
        line-height: 19px;
        cursor: pointer;
        position: fixed;
        bottom: 0px;
        left: 0px;
        display: none;
        z-index: $done_btn_z_index;


        &:active,
        &:focus,
        &:visited {
            outline: none;
        }

        @media (max-width: $mobile) {
            display: block;
        }


    }


}