@import '../sass/colors';
@import '../sass/dimensions';

.survey_effort_stats_container {
    margin-top: 30px;
    @media (max-width: $mobile) {
        margin-top: 20px;
    }

}

.survey_effort_stats_container__comparison {
    margin-top: 30px;
    max-width: 100%;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    @media (max-width: $mobile) {
        margin-top: 20px;
        grid-template-columns: 1fr;
    }
}


.monitoring_group_table_container {
    margin-top: 20px;
}

.monitoring_group_table_container__comparison {
    margin-top: 20px;
    max-width: 100%;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;

    @media(max-width: $monitoring_group_table_container_breakpoint) {
        grid-template-columns: 1fr;
    }

    @media (max-width: $mobile) {
        overflow-x: scroll;
        grid-template-columns: 1fr;
    }
}