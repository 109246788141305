@import '../../sass/dimensions';
@import '../../sass/colors';


.data_set_view {
    padding: 25px 20px;

    h5 {
        //This is for data set view title
        margin: 0px;
        padding: 0px;
        font-family: 'TTNormsBold';
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: color(data_set_view_title);
    }

    .details_container {
        margin-top: 18px;
        display: flex;
        flex-wrap: wrap;

        @media (max-width: $mobile) {
            margin-top: 12px;
        }

        .vertical_divider {
            border: 1px solid color(vertical_divider);
            margin: 0px 30px;

            @media (max-width: $mobile) {
                border: none;
                margin: 0px;
            }


        }


        p {
            margin: 0px;
            padding: 0px;
            font-family: 'TTNormsRegular';
            font-size: 16px;
            line-height: 25px;
            color: color(data_set_view_content);

            @media (max-width: $mobile) {
                margin-right: 30px;
            }

        }

        .data_set_view_filter_applied {
            font-family: 'TTNormsBold';
        }


        p:last-child {
            margin-right: 0px;
        }



    }
}

