@import '../../sass/colors';
@import '../../sass/dimensions';

.summary_card_section {

    display: grid;
    grid-template-columns: 1fr auto 1fr;

    @media (max-width: $mobile) {
        grid-template-columns: 1fr;
    }

    %section {
        margin: 0px;
        padding: 22px;

        .section_title {
            margin: 0px;
            padding: 0px;
            font-size: 12px;
            font-family: 'TTNormsBold';
            line-height: 14px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: color(summary_section_title);
        }

        .section_content {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            margin-top: 12px;
        }

        .section_value {
            margin: 0px;
            padding: 0px;
            font-size: 30px;
            font-family: 'TTNormsRegular';
            line-height: 24px;
            letter-spacing: 0.1em;
            color: color(summary_section_value);
        }

        .section_description {
            margin: 0px 0px 0px 10px;
            padding: 0px;
            font-size: 14px;
            font-family: 'TTNormsRegular';
            line-height: 17px;
            letter-spacing: -0.0428571px;
            color: color(summary_section_description);
        }
        



    }

    .section_one {
        @extend %section;
    }

    .section_two {
        @extend %section;

    }

    

}

.vertical_divider {
    border: 1px solid color(vertical_divider);
}