@import '../../sass/colors';
@import '../../sass/dimensions';



header {
    background-color: color(primary);
    height: $header_height;
    z-index: $header_z_index;
    padding: 20px 15px;
    z-index: 999;

    @media (max-width: $header_breakpoint_1) {

    }

    @media (max-width: $mobile) {
        height: auto;
        flex-wrap: wrap;
        position: relative;
        z-index: 1;    //This is so that tooltips scroll below
        padding: 0px 24px;
    }

    .logo {
        height: 45px;

    }


}

.dropdown {
    position: relative;
    display: inline-block;

}

.dropdown__icon{
    display: inline-block;
    height: 20px;
    fill: white;
    margin-left: 5px;
}

.dropdown__trigger{
    display: inline-block;
    
    @media (max-width: $mobile) {
        display: none;
        margin-bottom: 10px;
        padding: 30px 0; 
    }
}

.dropdown__trigger--mobile{
    display: none;
    @media (max-width: $mobile) {
        
    }
}

.dropdown__trigger--mobile + label{
    display: none;
    @media (max-width: $mobile) {
        display: inline-block;
        font-size: 16px;
        font-family: 'TTNormsMedium';
        line-height: 1.5em;
        margin-right: 0px;
        color: white;
        text-decoration: none;

    }
}

.dropdown__trigger--mobile:checked ~ .dropdown__list {
    display: flex;
}

.dropdown__list {
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: absolute;
    margin: 30px -15px;
    background-color: #522588;
    width: 180px;
    line-height: 1.4;
    z-index: 1;
    opacity: 0;
    transition: opacity 1s ease, visibility 0s 1s;
    visibility: hidden;
    @media (max-width: $mobile) {
        display: none;
        position: relative;
        margin-top: 0px;
        width: 100%;
        margin: 5px -40px;
        box-sizing: content-box;
        padding-right: 59px;
        opacity: 1;
        visibility: visible;
    }
}
.dropdown__item{
    margin-right: 0px;
    font-size: 5px;
    line-height: 1;
    margin-bottom: 5px;
    @media (max-width: $mobile) {
        margin-left: 19px;
    }
}

.dropdown:hover .dropdown__list {
    opacity: 1;
    transition: opacity 0.5s ease;
    visibility: visible;
}
.header-navbar{
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    align-items: center;
    margin: 0 auto;

    @media (max-width: $mobile) {
        padding: 20px 15px;
        flex-wrap: wrap;
    }

}

%header_page_link_container {
    display: flex;
    align-items: center;
    a {
        font-size: 16px;
        font-family: 'TTNormsMedium';
        line-height: 1.5em;
        margin-right: 40px;
        color: white;
        text-decoration: none;

        &:last-child {
            margin-right: 0px;
        }

        &:hover {
            color: color(header_link_hover);
        }
    }

    .dropdown__item{
        margin-right: 0px;
        font-size: 14px;
        line-height: 1.9;
    }

}


.header_page_link_container__open {
    @extend %header_page_link_container;


    @media (max-width: $mobile) {
        width: 100vw;
        display: grid;
        gap: 10px;
        margin: 20px 0;
    }

    a {
        @media (max-width: $mobile) {
            font-size: 16px;
            font-family: 'TTNormsMedium';
            line-height: 1.5em;
            margin-right: 0px;
            color: white;
            text-decoration: none;
        }
    }

}

.header_page_link_container__closed {
    @extend %header_page_link_container;
 
    @media (max-width: $mobile) {
        display: none;
    }
}

.header-navbar-login{
    width: fit-content;
    border: 3px solid #fff;
    padding: 5px 20px;
    text-transform: uppercase;
    text-decoration: none;
    background: initial;
    border-radius: 32px;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    i {
        margin-left: 15px;
    }

}
.about{
    display: flex;
    flex-direction:  column;
    z-index: 999;
}


.hamburger_button {
    align-items: center;
    background: 0 0;
    border: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    outline: 0;
    margin: 0;
    padding: 0;
    width: 40px;
    display: none;

    @media (max-width: $mobile) {
        display: block;
    }


    &:active,
    &:focus,
    &:visited {
        outline: none;
    }


    %hamburger_icon {

        display: block;
        height: 24px;
        padding: 0 5px;
        position: relative;
        width: 40px;

        .hamburger_icon__bar {

            background: #fff;
            display: block;
            height: 4px;
            position: absolute;
            width: 30px;
            margin-top: -2px;
            top: 50%;

            &::before {
                content: '';
                background: #fff;
                display: block;
                height: 4px;
                position: absolute;
                width: 30px;
                top: -10px
            }

            &::after {
                content: "";
                background: #fff;
                display: block;
                height: 4px;
                position: absolute;
                width: 30px;
                bottom: -10px;
            }




        }
    }

    .hamburger_icon__open {
        @extend %hamburger_icon;

        .hamburger_icon__bar {
            transform: rotate(45deg);

            &::before {
                opacity: 0;
                top: 0px;
            }

            &::after {
                bottom: 0px;
                transform: rotate(-90deg);
            }


        }


    }

    .hamburger_icon__closed {
        @extend %hamburger_icon;
    }



}