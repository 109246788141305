@import '../../sass/colors';

.survey_toggle_container {

    border: 1px solid color(toggle_header_border);
    border-top: none;
    border-radius: 0px 0px 10px 10px;
    margin-bottom: 16px;
    padding: 0px 22px;

    .checkbox_container {

        input[type='checkbox'] {
            opacity: 0;
            position: absolute;
            display: inline-block;
            vertical-align: middle;
            cursor: pointer;
        }

        label {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            font-family: 'TTNormsRegular';
            font-size: 14px;
            line-height: 19px;
            cursor: pointer;
        }

        input[type='checkbox']+label:before {
            content: '';
            background: #fff;
            border: 1px solid #575757;
            box-sizing: border-box;
            display: inline-block;
            vertical-align: middle;
            width: 20px;
            height: 20px;
            padding: 2px;
            margin-right: 16px;
            text-align: center;
            border-radius: 4px;
        }

        input[type='checkbox']:checked+label:before {
            background-color: color(primary);
        }
        
    }

}