@import '../../sass/colors';
@import '../../sass/dimensions';

.modal_toggle_title_container {

    @media (max-width: $mobile) {
        display: grid;
        justify-content: center;
        justify-items: center;
    }

    h4 {
        margin: 0px;
        padding: 0px;
        font-size: 20px;
        font-family: 'TTNormsBold';
        line-height: 19px;
        color: color(modal_subtitle);
    }

    p {
        margin: 10px 0px 0px 0px;
        padding: 0px;
        font-size: 16px;
        font-family: 'TTNormsRegular';
        line-height: 19px;
        letter-spacing: 0.01em;
        color: rgba(46, 59, 73, 0.5);
    }
}