@import '../../sass/colors';
@import '../../sass/dimensions';


//Keyframe definition
@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.loader-container {
  margin-left: -0.25em;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
}

.loader-border {
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  animation: spin 0.8s linear 0s infinite;

  .loader-core {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.loader_border_default_bg {
  background: linear-gradient(0deg,
      rgba(94, 43, 157, 0.1) 33%,
      rgba(94, 43, 157, 1) 100%);
}

.loader_border_set_one_bg {
  background: linear-gradient(0deg,
      rgba(94, 43, 157, 0.1) 33%,
      rgba(94, 43, 157, 1) 100%);
}

.loader_border_set_two_bg {
  background: linear-gradient(0deg,
      rgba(240, 82, 130, 0.1) 33%,
      rgba(240, 82, 130, 1) 100%);
}



