@import '../../sass/colors';
@import '../../sass/dimensions';


.custom_tooltip_container {
    box-shadow: 0px 1px 30px rgba(63, 63, 68, 0.15);
    border-radius: 4px;
    background-color: color(custom_tooltip_bg);
    padding: 20px;
    z-index: $save_btn_z_index + 1;

    .date_header {
        margin: 0px;
        padding: 0px;
        font-family: 'TTNormsRegular';
        font-size: 12px;
        line-height: 15px;
        color: white;
    }

    .all_locations_header {
        margin: 8px 0px 0px 0px;
        padding: 0px;
        font-size: 18px;
        font-family: 'TTNormsBold';
        line-height: 23px;
        color: white;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 15ch;
    }

    .details_div {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin-top: 8px;

        .description {
            margin: 0px;
            padding: 0px;
            font-family: 'TTNormsRegular';
            font-size: 16px;
            line-height: 20px;
            color: white;

        }

        .value {
            margin: 0px 0px 0px 30px;
            padding: 0px;
            font-family: 'TTNormsBold';
            font-size: 16px;
            line-height: 20px;
            color: white;
        }

    }

    .view_all_btn {
        margin: 8px 0px 0px 0px;
        padding: 0px;
        color: color(view_all_btn);
        font-family: 'TTNormsRegular';
        font-size: 16px;
        line-height: 20px;
        text-decoration: none;
        float: right;
    }

}