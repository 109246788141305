@import '../../sass/colors';
@import '../../sass/dimensions';

.treemap_legend_container__comparison {
    display: block;
    width: 100%;
    height: auto;
    overflow-y: scroll;
    background-color: color(treemap_legend_bg);
    border-left: none;
    padding: 30px;

    @media (max-width: $mobile) {
        padding: 20px;
    }


    .legend_item_container {

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin: 0px 0px 20px 0px;

        .item {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;

            .color {

                width: 18px;
                height: 18px;
                border-radius: 50%;

            }

            .name {

                margin: 0px 0px 0px 12px;
                padding: 0px;
                font-family: 'TTNormsBold';
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.01em;
                color: color(treemap_legend_name);
            }

        }

        .percent {
            margin: 0px 0px 0px 8px;
            padding: 0px;
            font-family: 'TTNormsBold';
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.01em;
            color: color(treemap_legend_name);

        }


        &:last-child {
            margin: 0px;
        }

    }
}


.treemap_legend_container {
    display: block;
    width: 30%;
    height: #{$treemap_height + 8px};
    overflow-y: scroll;
    background-color: color(treemap_legend_bg);
    border-left: 1px solid color(horizontal_divider);
    padding: 30px 20px;

    @media (max-width: $treemap_legend_breakpoint) {
        width: 40%;
    }

    @media (max-width: $mobile) {
        width: 100%;
        height: auto;
        border-left: none;
        padding: 20px;
    }


    .legend_item_container {

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin: 0px 0px 20px 0px;

        .item {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;

            .color {

                width: 18px;
                height: 18px;
                border-radius: 50%;

            }

            .name {
                margin: 0px 0px 0px 12px;
                padding: 0px;
                font-family: 'TTNormsBold';
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.01em;
                color: color(treemap_legend_name);
            }

        }

        .percent {
            margin: 0px 0px 0px 8px;
            padding: 0px;
            font-family: 'TTNormsBold';
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.01em;
            color: color(treemap_legend_name);

        }


        &:last-child {
            margin: 0px;
        }

    }

}