@import '../../sass/colors';
@import '../../sass/dimensions';

.info_item {


    .title {

        margin: 0px;
        padding: 0px;
        font-size: 12px;
        font-family: 'TTNormsBold';
        line-height: 14px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: color(summary_section_title);

        @media (max-width: $mobile) {
            font-size: 10px;
            line-height: 12px;
        }

    }

    .content {

        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        margin-top: 12px;

        .value {

            margin: 0px;
            padding: 0px;
            font-size: 30px;
            font-family: 'TTNormsRegular';
            line-height: 24px;
            letter-spacing: 0.1em;
            color: color(summary_section_value);

            @media (max-width: $mobile) {
                font-size: 20px;
                line-height: 25px;
            }

        }

        .description {

            margin: 0px 0px 0px 10px;
            padding: 0px;
            font-size: 12px;
            font-family: 'TTNormsRegular';
            line-height: 14px;
            text-transform: capitalize;
            color: color(summary_section_description);
        }

    }






}