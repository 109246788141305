@import '../../sass/colors';
@import '../../sass/dimensions';

.survey_set_container {

    margin: 16px 0px 34px 0px;
    display: grid;
    grid-template-columns: 1fr 120px 1fr;
    align-items: center;

    @media (max-width: $mobile) {
        grid-template-columns: 1fr;
        margin: 16px 0px 0px 0px;
        gap: 20px;
    }

    .set_1 {

        .content {
            margin-top: 20px;
        }

    }

    .set_2 {

        .content {
            margin-top: 20px;
        }
        
    }


    .modal_checkbox_container {
        input[type='checkbox'] {
            opacity: 0;
            position: absolute;
            display: inline-block;
            vertical-align: middle;
            cursor: pointer;
        }

        label {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            font-family: 'TTNormsRegular';
            font-size: 14px;
            line-height: 19px;
            cursor: pointer;
        }

        input[type='checkbox']+label:before {
            content: '';
            background: #fff;
            border: 1px solid #575757;
            box-sizing: border-box;
            display: inline-block;
            vertical-align: middle;
            width: 20px;
            height: 20px;
            padding: 2px;
            margin-right: 16px;
            text-align: center;
            border-radius: 4px;
        }

        input[type='checkbox']:checked+label:before {
            background-color: color(primary);
        }


    }

    .divider_image {
        margin-left: -60px; //This is purely for survey modal toggle for visual flair
    
        @media (max-width: $divider_image_breakpoint) {
            margin-left: -30px;
        }

        @media (max-width: $mobile) {
            margin-left: 0px;
            justify-self: center;
        }
    }




}

.set_title {
    margin: 0px;
    padding: 0px;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
    font-family: 'TTNormsBold';
    color: color(modal_set_title);
}

