@import '../sass/colors';
@import '../sass/dimensions';


.page_title_container {
    margin-top: 40px;

    @media (max-width: $mobile) {
        margin-top: 0px;
    }
}

.page_sub_title_container {
    margin-top: 55px;

    @media (max-width: $mobile) {
        margin-top: 30px;
    }
}

.data_set_view_container {
    margin-top: 20px;
}
.data_set_view_container__comparison {
    margin-top: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;

    @media (max-width: $mobile) {
        grid-template-columns: 1fr;
    }

}

.survey_locations_container {
    margin-top: 20px;
}

.survey_locations_container__comparison {
    margin-top: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;

    @media (max-width: $mobile) {
        grid-template-columns: 1fr;
    }
}

.products_and_materials_container {
    margin-top: 20px;
}

.products_and_materials_container__comparison {
    margin-top: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;

    @media (max-width: $mobile) {
        grid-template-columns: 1fr;
    }
}

.total_items_chart_container {
    margin-top: 30px;

    @media (max-width: $mobile) {
        margin-top: 16px;
    }
}

.total_items_chart_container__comparison {
    margin-top: 30px;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;

    @media (max-width: $mobile) {
        margin-top: 16px;
        grid-template-columns: 1fr;
    }
}